import { Button, Column, Grid, TextInput, Toggle } from "@carbon/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select, { MultiValue, SingleValue } from "react-select";
import useBeneficiary from "~/hooks/useBeneficiary";
import useBanks from "~/hooks/useBanks";
import { useNavigate } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import Margin from "~/components/Margin";
import * as Yup from "yup";
//getFirstLetters
import { Checkmark } from "@carbon/icons-react";
import { formatCPFCNPJ, onlyNumbers } from "~/services/utils";
import {
  getBeneficiariesAPI,
  IBeneficiaryFundBody,
  IBeneficiaryPixKey,
  IBodyBeneficiary,
  postBeneficiaryAPI,
  postBeneficiaryBankAccount,
  postBeneficiaryFundAPI,
  postBeneficiarykeyPIX,
  // IBodyBeneficiary,
} from "~/services/api/beneficiaries";

import { getBanks } from "~/services/api/banks";
import { ITypeBanks } from "~/types";
import { useFormik } from "formik";
import useProfile from "~/hooks/useProfile";
import useUser from "~/hooks/useUser";
// import ErrorIcon from "~/assets/Misuse.png";
// import CheckIcon from "~/assets/Checkmark--filled.png";
import Label from "~/components/Label";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";
import useFund from "~/hooks/useFund";
import { getFundsAPI } from "~/services/api/fund";
//import { onlyNumbers } from "~/services/utils";
import { IFund } from "~/types";
import MaskedTextInput from "~/components/InputMask";
import { IkeypixType, postCheckpixAPI } from "~/services/api/checkpix";
import { Loading } from "prosperita-dumbo-react";

const fadeIn = keyframes`
      from {
        opacity: 0;
        transform: translateY(-10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    `;

const Container = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) =>
    isVisible
      ? css`
          opacity: 1;
          animation: ${fadeIn} 0.3s ease-in-out;
        `
      : css`
          opacity: 0;
          height: 0;
          overflow: hidden;
        `}
`;
type MaskTypeOptions = {
  [key: string]: string;
};

type Values = {
  name: string;
  document_number: string;
  account_number: string;
  account_number_digit: string;
  agency: string;
  bank: string;
  document_number_key_cnpj: string;
  document_number_key_cpf: string;
  telefone: string;
  evp: string;
  email: string;
  type: string;
  fund: string[];
  beneficiary: string;
  key: string;
  bank_name: string;
};

const initialValues: Values = {
  name: "",
  document_number: "",
  email: "",
  evp: "",
  telefone: "",
  document_number_key_cpf: "",
  document_number_key_cnpj: "",
  account_number: "",
  account_number_digit: "",
  agency: "",
  bank: "",
  type: "",
  fund: [],
  beneficiary: "",
  key: "",
  bank_name: "",
};

type IBankAccountInfoPix = {
  account_number: string;
  account_number_digit: string;
  account_type: string;
  agency: string;
  bank: string;
  bank_name: string;
  document_number: string;
  key: string;
  key_type: string;
  message: string;
  name: string;
};

type SelectOptionsSelect = {
  value: string;
  label: string;
};

type SelectOption = {
  value: string;
  label: string;
};

const MaskIndex: MaskTypeOptions = {
  document_number_key_cnpj: "99.999.999/9999-99",
  document_number_key_cpf: "999.999.999-99",
  celphone: "(99)99999-9999",
  evp: "",
  email: "",
};

const validationSchema = Yup.object({
  full_name: Yup.string().required("Nome completo é obrigatório"),
  document_number: Yup.string().required("Número do documento é obrigatório"),
  company_name: Yup.string().required("Nome da empresa é obrigatório"),
  company_document_number: Yup.string().required(
    "Número do documento da empresa é obrigatório"
  ),
});

const GeneralBeneficiaries = () => {
  const navigate = useNavigate();

  const { setFunds, funds } = useFund();

  const { setCreatePasswordModalOpen, setSelectedItem } = useProfile();

  const { setLoading } = useLoading();

  const [stateToogleAcount, setStateToogleAcount] = useState<boolean>(false);
  const [stateTooglePix, setStateTooglePix] = useState<boolean>(false);
  const [valuesKeyBeneficiary, setvaluesKeyBeneficiary] = useState<IBeneficiaryPixKey>()
  const [stateValuesPix, setstateValuesPix] = useState<SelectOption[]>();
  const [stateValuesFieldKeyPix, setValuesFieldKeyPix] = useState<string>();
  const [keypixdata, setKeyPixData] = useState<IBankAccountInfoPix | null>();
  const [stepLoading, setStepLoading] = useState<boolean>(false);
  const [pixKeyAlertMessage, setPixStatusAlert] = useState<{ messege: string, color: string }>(
    {
      messege: "",
      color: ""
    }
  );
  const [value, setValue] = useState("");

  const { values, resetForm, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
  });
  const { setBeneficiaries } = useBeneficiary();
  const { banks, setBanks } = useBanks();

  const handleChangeSelect = (e: SingleValue<SelectOption>) => {
    const ValuesPix: SelectOptionsSelect[] = [
      {
        value: String(e?.value),
        label: String(e?.label),
      },
    ];
    setstateValuesPix(ValuesPix);
    setValuesFieldKeyPix("");
    setFieldValue("type", e?.value);
  };

  const handleValuesFieldKeyPix = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValuesFieldKeyPix(e.target.value);
  };

  const handleToogleHasBankAccount = (state: boolean) => {
    setStateToogleAcount(state);
  };

  const handleToogleHasKeyPIX = (state: boolean) => {
    setStateTooglePix(state);
    setvaluesKeyBeneficiary({ active: false, beneficiary: NaN, key: "", profile: NaN, type: "", fund: undefined })
    setKeyPixData(null)
    setPixStatusAlert({ messege: "", color: "" });
  };


  const handleMask = (inputValue: string) => {
    const value = onlyNumbers(inputValue);

    const mask = value.length > 11 ? "99.999.999/9999-99" : "999.999.999-99";

    setValue(applyMask(value, mask));
  };

  const applyMask = (value: string, mask: string) => {

    let maskedValue = "";

    let valueIndex = 0;

    for (let i = 0; i < mask.length; i++) {
      if (mask[i] === "9") {
        if (value[valueIndex]) {
          maskedValue += value[valueIndex];
          valueIndex++;
        } else {
          break;
        }
      } else {
        if (valueIndex < value.length) {
          maskedValue += mask[i];
        }
      }
    }

    return maskedValue;
  };

  const setValuesBanks = (banks: SingleValue<{ [key: string]: string }>) => {
    setFieldValue("bank_name", banks?.label);
    setFieldValue("bank", banks?.value);
  };

  const fetchFunds = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();
      setFunds(data.results);
    } catch (err: unknown) {
    }
  }, [setFunds]);

  const fetchBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiariesAPI();
      setBeneficiaries(data.results);
    } catch (err: unknown) {
    }
  }, []);

  const fetchBanks = useCallback(async () => {
    try {
      const { data } = await getBanks();
      setBanks(data.results);
    } catch (err: unknown) {
    }
  }, []);

  const fetchSendFundsBeneficiary = useCallback(
    async (data: IBeneficiaryFundBody) => {
      try {
        await postBeneficiaryFundAPI(data);
      } catch {
      }
    },
    []
  );

  const fundsOptions = useMemo(() => {
    return funds
      ?.filter((fund) => fund.raw_data.active === true)
      .map((fund: IFund) => ({
        value: String(fund.id),
        label: `${fund?.raw_data?.short_name?.trim()} - ${formatCPFCNPJ(fund?.raw_data?.document_number).trim()}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [funds]);

  const banksOptions = useMemo(() => {
    return banks
      ?.map((banks: ITypeBanks) => ({
        value: String(banks.id),
        label: String(`${banks.code} - ${banks.description}`),
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [banks]);

  const notifySuccess = () =>
    toast.success("Novo beneficiário criado com sucesso!");

  const notifyError = () =>
    toast.error(
      "Não foi possível adicionar o beneficiário. Tente novamente mais tarde."
    );

  const notifyErrorPix = () =>
    toast.error("Chave Pix inválida ou não existente");

  const { userProfile } = useUser();

  const optionsKeyWithCPF = useMemo(() => {
    return [
      { label: "CPF", value: "document_number_key_cpf" },
      { label: "Celular", value: "telefone" },
      { label: "Email", value: "email" },
      { label: "Aleatória", value: "evp" },
    ] as const;
  }, []);

  const optionskeyPix = useMemo(() => {
    return [
      { label: "CNPJ", value: "cnpj" },
      { label: "CPF", value: "cpf" },
      { label: "Celular", value: "telefone" },
      { label: "Email", value: "email" },
      { label: "Aleatória", value: "evp" },
    ] as const;
  }, []);

  const filteredValuesKey = optionsKeyWithCPF
    .map((option) => values[option.value])
    .filter((value) => value && value.trim() !== "");

  const payload: IBodyBeneficiary = useMemo(() => {
    return {
      name: values.name,
      document_number: values.document_number,
      active: false,
      profile: Number(userProfile?.id),
      status: "waiting_approval",
      raw_data: {
        pending_funds_approvations: true,
      },
    };
  }, [values, userProfile, filteredValuesKey, keypixdata]);

  const handleSelectFund = useCallback(
    (fundsOptions: MultiValue<SelectOption> | null) => {
      const values = fundsOptions
        ? fundsOptions.map((option) => option.value)
        : [];
      setFieldValue("fund", values);
    },
    [setFieldValue]
  );

  const fetchPixVerify = useCallback(async () => {
    setPixStatusAlert({ messege: "", color: "" });
    setStepLoading(true);
    setKeyPixData(null);
    const payload_pix = {
      key: values.type === "email" || values.type === "evp" ? filteredValuesKey[0] : onlyNumbers(filteredValuesKey[0]),
      type: values.type,
    };

    try {
      const response = await postCheckpixAPI(payload_pix as IkeypixType);

      const data = response.data as IBankAccountInfoPix;

      setKeyPixData(data as IBankAccountInfoPix);

      setvaluesKeyBeneficiary({
        active: true,
        key: filteredValuesKey[0],
        profile: Number(userProfile?.id),
        type: values.type,
      })
      const documentNumberInput = onlyNumbers(values.document_number || "");

      const documentNumberKeyPix = onlyNumbers(data?.document_number || "");

      if (documentNumberInput !== documentNumberKeyPix) {
        toast.error(
          "A chave Pix adicionada não corresponde ao documento cadastrado."
        );

        setPixStatusAlert({
          messege: `Este chave Pix não corresponde ao ${onlyNumbers(values.document_number).length > 11
            ? " CNPJ "
            : " CPF "} do beneficiário`, color: "red"
        });
      } else {
        toast.success("Chave Pix encontrada e validada");

        setPixStatusAlert({
          messege: `Tudo certo a chave Pix ${onlyNumbers(values.document_number).length > 11
            ? " CNPJ "
            : " CPF "} corresponde ao documento do beneficiário`, color: "green"
        });
      }
    } catch (error) {
      setPixStatusAlert({
        messege: `Chave Pix não encontrada`, color: "red"
      });
      notifyErrorPix();
    } finally {
      setStepLoading(false);
    }
  }, [keypixdata, filteredValuesKey, values]);

  const isValidButton = useCallback(() => {

    if (stateTooglePix) {
      const isDocumentNumberEqual = keypixdata
        ? onlyNumbers(values.document_number) ===
        onlyNumbers(keypixdata.document_number)
        : false;
      return keypixdata !== null && isDocumentNumberEqual;
    }
    const hasTwoNames =
      values.name && values.name.trim().split(" ").length >= 2;
    const hasDocumentNumber =
      values.document_number.length === 10 ||
      values.document_number.length >= 12;

    return hasTwoNames && hasDocumentNumber && values.fund.length > 0;

  }, [values, keypixdata, stateTooglePix, valuesKeyBeneficiary]);


  const createNewBeneficiary = useCallback(async () => {
    setLoading(true);
    setCreatePasswordModalOpen(false);

    try {
      const { data } = await postBeneficiaryAPI(payload as IBodyBeneficiary);
      postBeneficiaryBankAccount({
        account_number: values.account_number,
        account_number_digit:
          values.account_number.split("")[
          values.account_number.length - 1
          ],
        agency: values.agency,
        bank: Number(values.bank),
        profile: Number(userProfile?.id),
        beneficiary: Number(data?.id),
        status: "waiting_approval",
      })

      if (valuesKeyBeneficiary) {
        postBeneficiarykeyPIX({ ...valuesKeyBeneficiary, beneficiary: data.id })
      }

      const fetchFundBeneficiary = (fundId: number) => {
        return fetchSendFundsBeneficiary({
          fund: fundId,
          beneficiary: Number(data.id),
          raw_data: {
            status: "waiting_approval",
          },
        });
      };

      if (values.fund.length > 0) {
        const fetchPromises = values.fund.map((fundId) =>
          fetchFundBeneficiary(Number(fundId))
        );

        await Promise.all(fetchPromises);
      }
      resetForm();
      setSelectedItem("");
      setLoading(false);
      navigate("/app/beneficiaries/");
      notifySuccess();
    } catch (err: unknown) {
      setLoading(false);
      setFieldValue;
      notifyError();
    }
  }, [
    payload,
    resetForm,
    navigate,
    setLoading,
    setCreatePasswordModalOpen,
    setSelectedItem,
    setFieldValue,
  ]);

  useEffect(() => {
    fetchFunds();
    fetchBanks();
    fetchBeneficiary();
  }, []);

  return (
    <>
      <h2>Geral</h2>

      <form onSubmit={handleSubmit}>
        <Label text="Selecione o(s) Fundo(s)" />
        <Select
          id="funds"
          placeholder={"Selecione um ou mais fundos"}
          name="fund"
          isClearable={true}
          options={fundsOptions}
          isMulti
          onChange={(selectedOption) => handleSelectFund(selectedOption)}
        />
        <Margin mt={12} />
        <Container isVisible={true}>
          <Margin mt={12} />

          <TextInput
            id={"name"}
            name="name"
            labelText={"Nome/Razão Social"}
            placeholder={`Digite o Nome/Razão Social`}
            onChange={(e) => {
              setFieldValue("name", e.target.value);
            }}
          />
          <Margin mt={12} />
          <TextInput
            id={"document_number"}
            labelText={"CPF/CNPJ"}
            value={value}
            maxLength={18}
            onChange={(e) => {
              handleMask(e.target.value),
                setFieldValue("document_number", e.target.value);
            }}
            name="company_document_number"
            placeholder="Digite o CNPJ/CPF"
          ></TextInput>
          <Margin mt={12} />
          <Margin mb={30} />
          <Toggle
            id="toggle-1"
            labelText="Adicionar uma Conta Bancária"
            labelA=""
            onToggle={(e) => handleToogleHasBankAccount(e)}
            labelB=""
          />
          <Margin mt={22} />

          {stateToogleAcount && (
            <>
              <Select
                options={banksOptions}
                placeholder="Selecione o Banco..."
                name="bank"
                onChange={(e) => setValuesBanks(e)}
              ></Select>
              <Margin mt={15} />
              <TextInput
                id={"agency"}
                labelText={"Agência"}
                onChange={(e) => setFieldValue("agency", e.target.value)}
                name="agency"
                placeholder="0000"
              ></TextInput>
              <Margin mt={12} />
              <TextInput
                id={"account_number"}
                labelText={"Conta (Com dígito)"}
                onChange={(e) =>
                  setFieldValue("account_number", e.target.value)
                }
                name="account_number"
                placeholder="00000000"
              ></TextInput>
            </>
          )}
          <Margin mt={12} />

          <Margin mb={30} />
          <Toggle
            id="toggle-2"
            labelText="Adicionar uma Chave Pix"
            disabled={
              onlyNumbers(values.document_number).length < 11 ||
              (onlyNumbers(values.document_number).length > 11 &&
                onlyNumbers(values.document_number).length < 14)
            }
            onToggle={(e) => handleToogleHasKeyPIX(e)}
            labelA=""
            labelB=""
          />
          <Margin mt={22} />
          {stateTooglePix && (
            <>
              <Label text="Selecione o tipo de chave" />
              <Select
                placeholder={"Selecione"}
                options={optionskeyPix}
                onChange={(ValuesChange) => {
                  handleChangeSelect(ValuesChange);
                  optionsKeyWithCPF.forEach((item) => {
                    setFieldValue(item.value, "");
                  });
                }}
              />

              <Margin mt={12} />
              {stateValuesPix?.map((keysPix) => {
                return (
                  <>
                    <div style={{ display: "flex" }}>
                      <MaskedTextInput
                        mask={MaskIndex[keysPix.value]}
                        id={keysPix.value}
                        name={keysPix.value}
                        value={stateValuesFieldKeyPix}
                        placeholder={`Digite Sua Chave ${keysPix.label}`}
                        onChange={(e) => {
                          setFieldValue(keysPix.value, e.target.value);
                          handleValuesFieldKeyPix(e);
                        }}
                      />
                      <div style={{ position: "relative", width: "30%" }}>
                        <Button
                          size="md"
                          renderIcon={!stepLoading ? Checkmark : undefined}
                          iconDescription="Validar Chave"
                          kind="primary"
                          onClick={async () => {
                            await fetchPixVerify();
                          }}
                        >
                          {stepLoading && (
                            <div
                              style={{
                                position: "absolute",
                                right: "10%",
                                top: "22%",
                              }}
                            >
                              <Loading
                                small
                                backgroundOverlay="none"
                                colorSpinner="white"
                              ></Loading>
                            </div>
                          )}
                          Validar
                        </Button>
                      </div>
                    </div>
                    {keypixdata && (
                      <div>
                        <Margin mt={30} />
                        <h4 style={{ fontWeight: "bold" }}>
                          Nome:{" "}
                          <span style={{ fontWeight: "normal" }}>
                            {keypixdata.name}
                          </span>
                        </h4>
                        <h4 style={{ fontWeight: "bold" }}>
                          {onlyNumbers(keypixdata.document_number).length > 11
                            ? "CNPJ: "
                            : "CPF: "}
                          <span style={{ fontWeight: "normal" }}>
                            {formatCPFCNPJ(keypixdata.document_number)}
                          </span>
                        </h4>
                        <h4 style={{ fontWeight: "bold" }}>
                          Instituição:{" "}
                          <span style={{ fontWeight: "normal" }}>
                            {keypixdata.bank} - {keypixdata.bank_name}
                          </span>
                        </h4>
                        <h4 style={{ fontWeight: "bold" }}>
                          Agencia:{" "}
                          <span style={{ fontWeight: "normal" }}>
                            {keypixdata.agency}
                          </span>
                        </h4>
                        <h4 style={{ fontWeight: "bold" }}>
                          Conta:{" "}
                          <span style={{ fontWeight: "normal" }}>
                            {keypixdata.account_number}-
                            {keypixdata.account_number_digit}
                          </span>
                        </h4>
                      </div>
                    )}
                    <div style={{ margin: "10px auto", textAlign: "start" }}>
                      <p style={{ margin: "0", color: pixKeyAlertMessage.color }}>{pixKeyAlertMessage.messege}</p>
                    </div>
                  </>
                );
              })}
            </>
          )}

          <Margin mb={100} />

          <Column span={12}>
            <Margin mb={14} />
            <Grid>
              <Column span={6}>
                <Button
                  kind="tertiary"
                  className="action-button"
                  onClick={() => navigate("/app/beneficiaries/")}
                >
                  Cancelar
                </Button>
              </Column>
              <Column span={6}>
                <Button
                  kind="primary"
                  className="action-button"
                  type="submit"
                  disabled={!isValidButton()}
                  onClick={() => createNewBeneficiary()}
                >
                  Concluir
                </Button>
              </Column>
            </Grid>
          </Column>
        </Container>
      </form>
    </>
  );
};

export default GeneralBeneficiaries;
