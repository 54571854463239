import { format } from "date-fns";
import { Fragment, useCallback, useEffect, useMemo } from "react";
import Margin from "~/components/Margin";
import useAttend from "~/hooks/useAttend";
import { getAttendHistoryAPI } from "~/services/api/attend";
import { getFirstLetters } from "~/services/utils";
import { compareAsc } from "date-fns";
import { useParams } from "react-router-dom";

const parseStatusUpdate = (input: string): string => {
  let output = input;

  // Substituições específicas
  output = output.replace("->", "para");
  output = output.replace("Aberto", "Em aberto");
  output = output.replace("Return to Client", "Aguardando solicitante");
  output = output.replace(
    "Novo atendimento interno",
    "Novo chamado interno criado"
  );

  output = output.replace("Atendimento derivado", "Chamado interno derivado");

  return output;
};

const History = () => {
  const { id } = useParams();
  const { setHistory, history } = useAttend();
  const fetchHistory = useCallback(async () => {
    try {
      const { data } = await getAttendHistoryAPI(Number(id));
      setHistory(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setHistory, id]);

  const sortedHistory = useMemo(() => {
    return history
      .sort((a, b) => compareAsc(new Date(a.date), new Date(b.date)))
      .reverse();
  }, [history]);

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  return (
    <div style={{ marginTop: "20px" }}>
      {sortedHistory?.length > 0 &&
        sortedHistory?.map((item, index) => (
          <Fragment key={index}>
            {item.type === "Attend" && item.action === "Criação" && (
              <div
                style={{
                  padding: "40px 20px",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                }}
              >
                <p>
                  {item.actual_info.parent_attend
                    ? "Mensagem inicial"
                    : "Mensagem inicial "}
                </p>
                <Margin mb={20} />
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      background: "#b8b8b8",
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h3>{item.trigger && getFirstLetters(item.trigger)}</h3>
                  </div>
                  <Margin ml={20} />
                  <div>
                    <h4 style={{ fontWeight: "500" }}>{item.trigger}</h4>
                    <p style={{ fontSize: "14px", color: "#525252" }}>
                      {format(new Date(item.date), "dd/MM/yyyy - HH:mm")}
                    </p>
                    <Margin mb={50} />
                  </div>
                </div>
                <p style={{ marginLeft: "10px" }}>{item.actual_info.message}</p>
              </div>
            )}
            {/* Renderiza o item normalmente */}
            <div
              style={{
                padding: "40px 20px",
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              {item.type === "AttendTaskMessage" && (
                <div>
                  <p>
                    {item.actual_info.parent_attend
                      ? "Mensagem enviada no chamado Nº" +
                        item.actual_info.attend
                      : "Mensagem enviada no atendimento principal Nº" +
                        item.actual_info.attend}
                  </p>
                  <Margin mb={20} />
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        background: "#b8b8b8",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>
                        {item.actual_info.subject &&
                          getFirstLetters(item.actual_info.subject)}
                      </h3>
                    </div>
                    <Margin ml={20} />
                    <div>
                      <h4 style={{ fontWeight: "500" }}>
                        {item.actual_info.subject}
                      </h4>
                      <p style={{ fontSize: "14px", color: "#525252" }}>
                        {format(new Date(item.date), "dd/MM/yyyy - HH:mm")}
                      </p>
                      <Margin mb={30} />
                    </div>
                  </div>
                  <p style={{ marginLeft: "10px" }}>
                    {item.actual_info.message}
                  </p>
                </div>
              )}

              {item.type === "AttendTaskFile" && (
                <div>
                  <p style={{ display: "flex" }}>
                    {item.actual_info.parent_attend
                      ? "Arquivo enviado no chamado Nº" +
                        item.actual_info.attend
                      : "Arquivo enviado no atendimento principal Nº" +
                        item.actual_info.attend}
                    {` enviado por ${item.trigger} - `}
                    <span style={{ color: "#525252" }}>
                      {format(new Date(item.date), "dd/MM/yyyy - HH:mm")}
                    </span>
                  </p>
                  <Margin mb={10} />
                  <p
                    style={{ color: "blue ", cursor: "pointer" }}
                    onClick={() =>
                      window.open(item?.actual_info.file, "_blank")
                    }
                  >
                    {item?.actual_info.description}
                  </p>
                </div>
              )}

              {item.type !== "AttendTaskMessage" &&
                item.type !== "AttendTaskFile" && (
                  <p style={{ display: "flex" }}>
                    {parseStatusUpdate(item.description)}
                    {" - "}
                    <span style={{ color: "#525252" }}>
                      {format(new Date(item.date), "dd/MM/yyyy - HH:mm")}
                    </span>
                  </p>
                )}
            </div>
          </Fragment>
        ))}
    </div>
  );
};

export default History;
