import {
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button,
  Pagination,
} from "@carbon/react";
import {
  TrashCan,
  Add,
  Save,
  Download,
  Renew,
  Edit,
} from "@carbon/icons-react";
import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";

// import { useFormik } from "formik";
import { getProfilesAPI } from "~/services/api/profile";
import Padding from "~/components/Padding";
import FilterModal from "./FilterModal";
import useProfile from "~/hooks/useProfile";
import { formatCPFCNPJ } from "~/services/utils";
import { permissions } from "~/services/permissions";

import EditPermissionsApproverModal from "./EditPermissionsApproverModal";
import EditProfileModal from "./EditProfileModal";

const tableHeadersProfile: string[] = [
  "Nome",
  "CPF",
  "Perfil",
  "",
  "Permissões",
  "",
];

const canReadProfiles = permissions.includes("can_read_profiles");
const canCreateProfiles = permissions.includes("can_create_profiles");
// const canEditProfiles = permissions.includes("can_edit_profiles")
// const canDeleteProfiles = permissions.includes("can_delete_profiles");

const clientTypeDisplay: { [key: string]: string } = {
  client: "Usuário comum (Empresa)",
  manager: "Usuário Master (Empresa)",
  internal: "Atendente",
  area_manager: "Gestor de àrea",
  tool_manager: "Gestor de ferramenta",
};

const Profiles = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const {
    setProfiles,
    profiles,
    setOpenDeleteProfileModal,
    openDeleteProfileModal,
    setOpenEditProfileModal,
    setDetailsProfile,
    openEditProfileModal,
  } = useProfile();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const paginatedProfiles = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return profiles?.slice(start, end);
  }, [currentPage, itemsPerPage, profiles]);

  // const [searchTerm, setSearchTerm] = useState("");
  // const [filteredProfiles, setFilteredProfiles] = useState(profiles);

  // const { values } = useFormik({
  //   initialValues: {
  //     search: "",
  //   },
  //   onSubmit: (values: { search: string }) => {
  //     console.log(values);
  //   },
  // });

  // const filterProfiles = () => {
  //   const filtered = profiles.filter(
  //     (profile) =>
  //       profile.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       profile.document_number
  //         .replace(/[.-]/g, "")
  //         .includes(searchTerm.toLowerCase().replace(/[.-]/g, ""))
  //   );
  //   setFilteredProfiles(filtered);
  // };

  const fetchProfiles = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getProfilesAPI({ mode: "list" });
      setProfiles(data.results.reverse());

      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  // useEffect(() => {
  //   filterProfiles();
  // }, [searchTerm, filterProfiles]);

  // useEffect(() => {
  //   setSearchTerm(values.search);
  // }, [values.search]);
  return (
    <Grid>
      <Column span={16}>
        <Margin mt={64} /> <h1>Usuários</h1>
        <Margin mt={34} /> <Margin mt={20} />
        <TableContainer>
          <Margin mb={20} />
          <TableToolbar>
            {canReadProfiles && (
              <Button
                tabIndex={0}
                onClick={() => {
                  fetchProfiles();
                }}
                kind="secondary"
                renderIcon={Renew}
              >
                Atualizar
              </Button>
            )}
            {!canReadProfiles && (
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h4 style={{ position: "relative", left: "45%" }}>
                  Você não possui permissão para visualizar usuários
                </h4>
              </div>
            )}
            <TableBatchActions onCancel={console.log} totalSelected={0}>
              <TableBatchAction
                tabIndex={0}
                renderIcon={TrashCan}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Add"
                tabIndex={0}
                renderIcon={Add}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Save"
                tabIndex={0}
                renderIcon={Save}
                onClick={console.log}
              >
                Save
              </TableBatchAction>
              <TableBatchAction
                tabIndex={0}
                renderIcon={Download}
                onClick={console.log}
              >
                Download
              </TableBatchAction>
            </TableBatchActions>
            <TableToolbarContent>
              {/* <TableToolbarSearch
                tabIndex={0}
                onChange={handleChange}
                value={values.search}
                id="search"
                placeholder="Pesquisar"
              /> */}
              {/* {user.type === "tool_manager" && (
                <Tooltip defaultOpen label="Exportar relatório" align="top">
                  <Button
                    kind="ghost"
                    hasIconOnly
                    renderIcon={Report}
                    id="filter"
                  />
                </Tooltip>
              )}
              <Tooltip defaultOpen label="Filtrar" align="top">
                <Button
                  kind="ghost"
                  hasIconOnly
                  renderIcon={Filter}
                  id="filter"
                  onClick={() => setOpenModal(true)}
                />
              </Tooltip> */}

              {canCreateProfiles && (
                <Button
                  tabIndex={0}
                  onClick={() => {
                    navigate("/app/profiles/new/general");
                  }}
                  kind="primary"
                >
                  Novo usuário
                </Button>
              )}
            </TableToolbarContent>
          </TableToolbar>
          {canReadProfiles && (
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableSelectAll
                  name={""}
                  id={""}
                  onSelect={console.log}
                  checked={false}
                /> */}
                  {tableHeadersProfile.map((columnTitle, index) => {
                    return (
                      <TableHeader key={String(index)}>
                        {columnTitle}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedProfiles.length === 0 ? (
                  <TableCell colSpan={tableHeadersProfile.length + 1}>
                    <Padding pt={24} pb={24} pl={12}>
                      <div
                        style={{
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Nenhum usúario até o momento</h4>
                      </div>
                    </Padding>
                  </TableCell>
                ) : paginatedProfiles.length > 0 ? (
                  paginatedProfiles.map((profile, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                      ariaLabel={""}
                    /> */}
                      <TableCell>{profile.name}</TableCell>
                      <TableCell>
                        {formatCPFCNPJ(profile?.document_number)}
                      </TableCell>
                      <TableCell>
                        <p>
                          {" "}
                          {profile.type.includes("tool_manager") &&
                            "Gestor de ferramenta"}
                        </p>
                        <p>
                          {" "}
                          {profile.type.includes("area_manager") &&
                            "Gestor de Área"}
                        </p>
                        <p>
                          {" "}
                          {profile.type.includes("internal") && "Atendente"}
                        </p>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Button
                          kind="ghost"
                          hasIconOnly
                          iconDescription="Editar Perfis"
                          renderIcon={Edit}
                          id="filter"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenEditProfileModal(true);
                            setDetailsProfile(profile);
                            // setAreaDetails(area);
                            // setOpenDeleteAreaModal(true);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {profile?.beneficiary_approver === false &&
                          profile.payment_approver === false &&
                          profile.payment_company_approver === false &&
                          "Nenhuma"}
                        <p>
                          {" "}
                          {profile?.beneficiary_approver === true &&
                            "Aprovador de Beneficiários"}
                        </p>
                        <p>
                          {profile?.payment_approver === true &&
                            "Aprovador de Pagamentos"}
                        </p>
                        <p>
                          {profile?.payment_company_approver === true &&
                            "Aprovador de Pagamentos (Empresa)"}
                        </p>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Button
                          kind="ghost"
                          hasIconOnly
                          iconDescription="Editar Permissões"
                          renderIcon={Edit}
                          id="filter"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenDeleteProfileModal(true);
                            setDetailsProfile(profile);
                            // setAreaDetails(area);
                            // setOpenDeleteAreaModal(true);
                          }}
                        />
                      </TableCell>
                      {/* <TableCell>{profile.area}</TableCell> */}
                    </TableRow>
                  ))
                ) : (
                  paginatedProfiles.map((profile, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                      ariaLabel={""}
                    /> */}
                      <TableCell>{profile.name}</TableCell>
                      <TableCell>
                        {" "}
                        {formatCPFCNPJ(profile?.document_number)}
                      </TableCell>
                      <TableCell>{clientTypeDisplay[profile.type]}</TableCell>
                      <TableCell>
                        {clientTypeDisplay[profile.type]}

                        <Button
                          kind="ghost"
                          hasIconOnly
                          iconDescription="Excluir"
                          renderIcon={TrashCan}
                          id="filter"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenDeleteProfileModal(true);
                            // setDetailsProfile(profile);
                            // setAreaDetails(area);
                            // setOpenDeleteAreaModal(true);
                          }}
                        />
                      </TableCell>

                      {/* <TableCell>
                        {canDeleteProfiles && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Excluir"
                            renderIcon={TrashCan}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenDeleteProfileModal(true);
                              setDetailsProfile(profile);
                              // setAreaDetails(area);
                              // setOpenDeleteAreaModal(true);
                            }}
                          />
                        )}
                      </TableCell> */}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
          <Pagination
            totalItems={profiles.length}
            pageSize={itemsPerPage}
            pageSizes={[5, 10, 20, 30, 40, 50]}
            onChange={({ page, pageSize }) => {
              setCurrentPage(page);
              setItemsPerPage(pageSize);
            }}
            page={currentPage}
            size="md"
          />
        </TableContainer>
        <Margin mb={50} />
      </Column>
      <FilterModal onClose={() => setOpenModal(false)} open={openModal} />
      <EditPermissionsApproverModal
        open={openDeleteProfileModal}
        onClose={() => setOpenDeleteProfileModal(!openDeleteProfileModal)}
      />

      <EditProfileModal
        open={openEditProfileModal}
        onClose={() => setOpenEditProfileModal(!openEditProfileModal)}
      />
    </Grid>
  );
};

export default Profiles;
