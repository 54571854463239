import styled from "styled-components";
import Margin from "./Margin";

const BadgeLabel = styled.span``;

const BadgeContainer = styled.div<BadgeProps>`
  background-color: ${({ color }) => color ?? "green"};
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: auto;
  border-radius: 25px;
  padding: 12px;
  max-width: 150px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
  ${BadgeLabel} {
    color: ${({ textColor }) => textColor ?? "black"};

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.32px;
  }
`;

const BadgeIcon = styled.img``;

type BadgeProps = {
  color?: string;
  textColor?: string;
  icon?: string;
  label?: string;
  iconSize?: string;
};

const Badge = (props: BadgeProps) => {
  const { color, textColor, label, icon, iconSize } = props;

  return (
    <>
      <BadgeContainer color={color} textColor={textColor}>
        {BadgeIcon && (
          <BadgeIcon
            src={icon}
            style={iconSize ? { height: `${iconSize}px` } : {}}
          />
        )}
        <Margin ml={3} />
        <BadgeLabel>{label}</BadgeLabel>
      </BadgeContainer>
    </>
  );
};

export default Badge;
